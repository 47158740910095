// eslint-disable-next-line no-redeclare,no-unused-vars
function convertToMoment(value, type) {
  var momentObj;

  // datetime
  if (type == 'datetime') {
    value = value.trim();
    if (value.length == 10) value += ' 08:00';
    else if (value.length < 13 && !str_contains(value, ':')) value += ':00';
  }

  // date
  else if (type == 'date') {
    if (isNumeric(value) && value.length <= 3 && type == 'date')
      value =
        moment().year() +
        '-' +
        (moment().month() + 1) +
        '-' +
        value.substr(0, 2);
    else if (isNumeric(value) && value.length == 8)
      value =
        value.slice(4, 8) + '-' + value.slice(2, 4) + '-' + value.slice(0, 2);
    else if (isNumeric(value) && value.length == 6)
      value =
        moment().format('Y').slice(0, 2) +
        value.slice(4, 6) +
        '-' +
        value.slice(2, 4) +
        '-' +
        value.slice(0, 2);
    else if (str_contains(value, '.') || str_contains(value, ',')) {
      value = value.replace(',', '');
      if (substr_count(value, '.') == 1) value += '.' + moment().format('YYYY');
      else if (str_ends_with(value, '.')) value += moment().format('YYYY');
      value = strtotime(value) * 1000;
    }
  }

  // week
  else if (
    type == 'week' &&
    (!str_contains(value, '-') || str_contains(value, 'W'))
  ) {
    if (str_contains(value, 'W')) {
      var year, week;
      [year, week] = value.split('-W');
      momentObj = moment().year(year).week(week);
    } else {
      if (isString(value)) value = value.replace(/[^0-9]+/, '');
      momentObj = moment().week(value);
    }
  }

  // time
  else if (type == 'time') {
    var hour, minute;
    [hour, minute] = value.split(':');
    if (!minute) minute = 0;
    momentObj = moment().hour(hour).minute(minute);
  }

  if (!momentObj) momentObj = moment(value);
  return momentObj;
}
